import React, { useState, useEffect } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import RemittanceReport from "./RemittanceReport";
import ApplicationUpdate from "./ApplicationUpdate";
import ArrearsReport from "./ArrearsReport";
import { getConfig } from "../../config";
import ReportDateFilter from "./ReportDateFilter";
import ReportDirectorFilter from "./ReportDirectorFilter";


const reportChoices = {
  "remittance_report": {
    name: "Remittance Report",
    procedure: "remittance_report",
    dateRequired: true,
    report: (groups, dateRange) => <RemittanceReport groups={groups} startDate={dateRange.startDate} endDate={dateRange.endDate} />
  },
  "application_update": {
    name: "Application Update",
    procedure: "application_update",
    dateRequired: true,
    report: (groups, dateRange) => <ApplicationUpdate groups={groups} startDate={dateRange.startDate} endDate={dateRange.endDate} />
  },
  "arrears_report": {
    name: "Arrears Report",
    procedure: "arrears_report",
    dateRequired: false,
    report: (groups, _) => <ArrearsReport groups={groups} />
  }
}

export const Reports = () => {
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [report, setReport] = useState(reportChoices["remittance_report"]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString(),
    endDate: undefined
  });

  const dateRangeUpdated = (startDate, endDate) =>
    setDateRange({ startDate, endDate });

  const structureReport = (remittances) => {
    const groupsByName = {};
    const groups = [];

    remittances.forEach(remittance => {
      let group = groupsByName[remittance.LoanFdGroup];

      if (!group) {
        group = { name: remittance.LoanFdGroup, subGroupsByName: {}, subGroups: [], remittanceCount: 0 };
        groups.push(group);
        groupsByName[group.name] = group;
      }
      group.remittanceCount++;

      let subGroup = group.subGroupsByName[remittance.LoanFdSubGroup];
      if (!subGroup) {
        subGroup = { name: remittance.LoanFdSubGroup, directorsByName: {}, directors: [], remittanceCount: 0 };
        group.subGroups.push(subGroup);
        group.subGroupsByName[subGroup.name] = subGroup;
      }
      subGroup.remittanceCount++;

      let director = subGroup.directorsByName[remittance.LoanFdName];
      if (!director) {
        director = { id: remittance.LoanFdId, name: remittance.LoanFdName, remittances: [] };
        subGroup.directors.push(director);
        subGroup.directorsByName[director.name] = director;
      }
      director.remittances.push(remittance)
    });
        
    return groups;
  }

  const {
    user,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    const loadReport = async () => {
      if (!getAccessTokenSilently) return;

      const reportRoot = getConfig().reportsUri;
      const token = await getAccessTokenSilently({
        detailedResponse: true,
        audience: reportRoot,
        scope: "openid profile email offline_access'"
      });
      const parameters =
        dateRange.endDate
          ? `&end-date=${dateRange.endDate.split('T')[0]}`
          : ``;

      setGroups([]);
      setFilteredGroups([]);

      const response = await fetch(
        report.dateRequired
          ? `${reportRoot}?report=${report.procedure}&start-date=${dateRange.startDate.split('T')[0]}${parameters}`
          : `${reportRoot}?report=${report.procedure}${parameters}`, {
        headers: {
          Authorization: `Bearer ${token.access_token}`
        },
      });

      const structuredReport = structureReport(await response.json());
      setGroups(structuredReport);
      setFilteredGroups(structuredReport);
    };
    loadReport();
  },
    [report, dateRange, getAccessTokenSilently, user.email]
  );

  
  return <>
    <FormGroup className="report-choice">
      <Label for="reportChoice">
        Report
      </Label>
      <Input
        id="reportChoice"
        name="reportChoice"
        type="select"
        onChange={event => setReport(reportChoices[event.target.value])}
      >
        {Object.values(reportChoices)
          .map(report =>
            <option value={report.procedure} key={report.procedure}>{report.name}</option>)}
      </Input>
    </FormGroup>
    {report.dateRequired &&
      <ReportDateFilter startDate={dateRange.startDate} endDate={dateRange.endDate} onDateRangeUpdated={dateRangeUpdated} />}
    <ReportDirectorFilter groups={groups} updateFilteredEntries={setFilteredGroups} />

    {(!!report) ? report.report(filteredGroups, dateRange) : <></>}
  </>
};

export default withAuthenticationRequired(Reports, {
  onRedirecting: () => <Loading />,
});
