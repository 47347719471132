import React, { Fragment } from "react";
import "./Report.scss"
import logo from "../../assets/fs-logo.svg"

const RemittanceReport = (props) => {
    const dateFormatter = new Intl.DateTimeFormat(
        navigator.languages || navigator.language, {
        dateStyle: 'medium',
    })

    const currencyFormatter = new Intl.NumberFormat(
        navigator.languages || navigator.language, {
        style: 'currency',
        currency: 'GBP'
    })

    const numberFormatter = new Intl.NumberFormat(
        navigator.languages || navigator.language, {
    })

    if (!props.groups || !props.startDate) return <></>

    const startDate = new Date(Date.parse(props.startDate));
    const endDate = props.endDate && new Date(Date.parse(props.endDate));
    
    return props.groups
        .filter(group => group.remittanceCount > 0)
        .map(group =>
            <section className="report-group report-remittance" key={group.name}>
                {props.groups.length > 1
                    ? <h2 key={`${group.name}-heading`}>{group.name}</h2>
                    : <></>
                }
                {group.subGroups
                    .filter(subGroup => subGroup.remittanceCount > 0)
                    .map(subGroup =>
                        <section className="sub-group" key={subGroup.name}>
                            {group.subGroups.length > 1
                                ? <h3>{subGroup.name}</h3>
                                : <></>
                            }
                            {subGroup.directors
                                .filter(director => director.remittances.length > 0)
                                .map(director =>
                                    <section className="report-summary" key={director.name}>
                                        <section className="report-heading">
                                            <img src={logo} alt="FuneralSafe logo" />
                                            <section className="report-scope">
                                                <h4>Remittance Report</h4>
                                                <dl>
                                                    <dt>Date:</dt>
                                                    <dd>{
                                                        endDate && endDate > startDate
                                                            ? `${dateFormatter.format(startDate)} - ${dateFormatter.format(endDate)}`
                                                            : dateFormatter.format(startDate)}</dd>
                                                    <dt>Account Name:</dt>
                                                    <dd>{director.name}</dd>
                                                </dl>
                                            </section>
                                        </section>
                                        <section className="report-body" key={director.name + '-body'}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="date">Issue date</th>
                                                        <th className="date">Paid out date</th>
                                                        <th className="number">Loan Amount</th>
                                                        <th className="reference">Reference</th>
                                                        <th>Name</th>
                                                        {group == "legal" &&
                                                            <>
                                                            <th>Net Amount</th>
                                                            <th>Finance Charge</th>
                                                            <th>Gross Amount</th>
                                                            <th>%Finance Charge</th>
                                                            </>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {director.remittances.map((remittance, index) => {
                                                        const loanAmount = remittance["LoanValue"] && Number.parseFloat(remittance["LoanValue"]);
                                                        const issueDate = new Date(Date.parse(remittance.LoanIssuedDate));
                                                        const paidDate = new Date(Date.parse(remittance.LoanPaidDate));
                                                        const netAmount = remittance["NetAmount"] && Number.parseFloat(remittance["NetAmount"]);
                                                        const dirFinCharge = remittance["DirFinCharge"] && Number.parseFloat(remittance["DirFinCharge"]);
                                                        const grossAmount = remittance["GrossAmount"] && Number.parseFloat(remittance["GrossAmount"]);
                                                        const dirFinChargePct = remittance["DirFinChargePct"] && Number.parseInt(remittance["DirFinChargePct"]);

                                                        return <tr key={`${remittance.AppFirstName}-${remittance.AppLastName}`}>
                                                            <td className="date">{remittance.LoanIssuedDate && dateFormatter.format(issueDate)}{ /* dd/mm/yyyy */}</td>
                                                            <td className="date">{remittance.LoanPaidDate && dateFormatter.format(paidDate)}</td>
                                                            <td className="number">{loanAmount && currencyFormatter.format(loanAmount)}</td>
                                                            <td className="number">{remittance.OrderNumber}</td>
                                                            <td>{`${remittance.AppFirstName} ${remittance.AppLastName}`}</td>
                                                            {group == "legal" &&
                                                                <>
                                                                    <td className="number">{netAmount && currencyFormatter.format(netAmount)}</td>
                                                                    <td className="number">{dirFinCharge && currencyFormatter.format(dirFinCharge)}</td>
                                                                    <td className="number">{grossAmount && currencyFormatter.format(grossAmount)}</td>
                                                                    <td className="number">{dirFinChargePct && `${numberFormatter.format(dirFinChargePct)}%`}</td>
                                                                </>
                                                            }
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </section>
                                    </section>
                                )}
                        </section>
                    )}
            </section>
        );
}

export default RemittanceReport;
