import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Loading from "./components/Loading";
import Layout from "./Layout";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Reports from "./views/Reports/Reports";
import Users from "./views/Users/Users";
import { getConfig } from "./config";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const routes = [
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/profile",
        element: <Profile />
      },
      {
        path: "/users",
        element: <Users />
      },
      {
        path: "/reports",
        element: <Reports />
      },
    ]
  },
];

const router = createBrowserRouter(routes);

const App = () => {
  const {
    isLoading,
    error,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    const getUsers = async () => {
      if (!user || !getAccessTokenSilently) return;
      const usersRoot = getConfig().usersUri;
      const directorsRoot = getConfig().directorsUri;
      const token = await getAccessTokenSilently({
        detailedResponse: true,
        audience: usersRoot,
        scope: "openid profile email offline_access'"
      });

      const users = await fetch(
        usersRoot, {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          // Email: user.email,
        },
      });

      console.log(JSON.stringify(users))
      const directors = await fetch(
        directorsRoot, {
        headers: {
          Authorization: `Bearer ${token.access_token}`
          // Email: user.email,
        },
      });

      console.log(JSON.stringify(directors))
    }

    getUsers().then(users => {
      console.log(JSON.stringify(users))
    });
  },
    [getAccessTokenSilently, user?.email]
  );

  if (error)
    return <div>Oops... {error.message}</div>;

  return isLoading
    ? < Loading />
    : < RouterProvider router={router} />;
};


export default App;
